import React from 'react'
import '../../scss/pages/profiles.scss'

import { siteMetadata } from '../../../gatsby-config'
import ProfileLayout from 'components/layout/profiles'
import Meta from 'components/meta'
import peopleData from '../../../content/people.json';

const person = peopleData.people[4];

const Lutes = ({ data, location }) => (
  <ProfileLayout location={location} profile_name="Naomi M. Lutes" profile_key="lutes">
      <Meta site={siteMetadata} title="Naomi M. Lutes" />
	  	<div className="row">
			<div className="col-md-9 pr-md-5">
				<h4>About</h4>

				<p>Naomi has a diverse practice, representing clients in criminal and regulatory trials and appeals, with a particular focus on corporate crime, asset forfeiture, sexual assault, and appellate advocacy. She has appeared before all levels of court in Ontario as well as the Nova Scotia Court of Appeal and the Supreme Court of Canada.</p>

				<p>In addition to her criminal law practice, Naomi regularly appears at the Law Society Tribunal, representing lawyers in disciplinary proceedings, as well as before other administrative and disciplinary tribunals.</p>
				
				<p>Naomi is a member of the Criminal Lawyers' Association, the Advocates' Society, the Ontario Bar Association, and the Toronto Lawyers Association. She acts as duty counsel with the Court of Appeal’s Pro Bono Inmate Appeal Program and has co-chaired the OsgoodePD National Symposium on Money Laundering and Financial Crimes since 2017.</p>

				<p>Along with colleagues Brian Greenspan and Seth Weinstein, she is the co-author of “Corporate Criminal Liability: Principals and Principles” in <i>The Controlling Mind – Exercising Legal Control: Its Obligations and Liabilities</i>, ed. Barry Lipson, Carswell, 2012. She is also the author of “The Role of Defence Counsel at Sentencing” in <i>Sentencing in Canada: Essays in Law, Policy and Practice</i>, Irwin Law, 2020.</p>

				<p>Naomi earned an Honours Bachelor of Arts from Queen’s University in 2007 where she studied Classics and Linguistics. She received her Juris Doctor from the University of Toronto in 2010, graduating with honours standing. During law school, she was awarded the Genest Murray Prize in Evidence, the Rocco Marcello Prize in Securities Law, and the Gowlings Prize in Corporate Finance. She articled with our firm and joined us as an associate following her call to the Ontario bar in 2011. </p>


			</div>
			<div className="col-md-3 contact-card">
				<h4 className="text-primary">Get in touch</h4>
				<p className="text-primary">15 Bedford Rd.<br/>
				Toronto, Ontario <br/>
				M5R 2J7</p>

				<p><a className="d-flex align-items-center" href={"tel:"+person.phone_code}><img className="icon pr-1" src="/img/icons/blue-phone.png"/>{person.phone}</a></p>
				<p><a className="d-flex align-items-center" href={"mailto:"+person.email}><img className="icon pr-1" src="/img/icons/blue-email.png"/>{person.email}</a></p>
				<p><a className="d-flex align-items-center" href={"mailto:"+person.assistant_contact}><img className="icon" src="/img/icons/blue-whatsapp.png"/>Contact {person.short_name}’s Assistant</a></p>
				{/* <p><a className="d-flex align-items-center" ><img className="icon" src="/img/icons/blue-twitter.png"/>Follow {person.short_name} on Twitter</a></p> */}

			</div>
		</div>	  
  </ProfileLayout>
)
export default Lutes
