import { Link } from 'gatsby'
import React from 'react'

import Layout from 'components/layout'

class ProfileLayout extends React.Component {
  render() {
	const { location, children, profile_name, profile_key } = this.props
	console.log(profile_key);
    return (
      <Layout location={location}>
        <div>
			<section className="hero profile" style={{backgroundImage: "url('/img/people/"+profile_key+"-cover.jpg')"}}>
				<div className="container pb-5 pt-md-5 pb-md-4">
					<div className="row top-hero align-items-end">
						<div className="col-md-12 d-flex justify-content-between align-items-end">
							<h1 className="h1 pt-md-5 pl-3 pl-md-4 mb-0">{profile_name}</h1>
							<div>
								<Link className="btn-link text-white" to="/team">Back to Team</Link>
							</div>
						</div>						
					</div>
				</div>
			</section>
			<section className="bg-black fade-black-top pb-5">
				<div className="container bg-white mb-5">
					<div className="row">
						<div className="col-md-12 py-5 px-4 px-md-5">
							{children}
						</div>
					</div>
				</div>
			</section>	
        </div>
      </Layout>
    )
  }
}

export default ProfileLayout